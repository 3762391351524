import type { CalendarEvent } from '@/components/calendar/Calendar';
import type { AvailabilityPerDate } from '@/domain';
import { EventTypes } from '@/domain';
import {
    createMaxRentalDurationEventsPerDate,
    createMinRentalDurationEventsPerDate,
    getBookingEventFollowingSelectionEvent,
    getExpiredEventsWithSelectEvent,
    getInvalidCheckinDayEventListPerDate,
    getInvalidCheckoutDayEventListPerDate,
    isOnSameDay,
    moveBookingEventEndDateBackOneDay,
    moveBookingEventStartDateForwardOneDay,
} from '@/hooks/utils';

// This is the editted version of the events we receive so it should be sorted
const bookingEventsWithoutSelectEvent = ({
    availabilityPerDate,
    bookingEvents,
    activeMonthDate,
    today,
}: {
    availabilityPerDate?: AvailabilityPerDate[];
    bookingEvents: CalendarEvent[];
    activeMonthDate: Date;
    today: Date;
}): CalendarEvent[] => {
    const invalidCheckinDayEventList = getInvalidCheckinDayEventListPerDate({
        bookingEvents,
        availabilityPerDate,
        activeMonthDate,
        today,
    });
    // This is used so users can select the last day of a booking
    const updatedEvents = bookingEvents.map((event) => moveBookingEventEndDateBackOneDay(event)).concat(invalidCheckinDayEventList);

    return updatedEvents;
};

const bookingEventsWithSelectStartEvent = ({
    availabilityPerDate,
    bookingEvents,
    selectedStartDate,
    activeMonthDate,
}: {
    availabilityPerDate?: AvailabilityPerDate[];
    bookingEvents: CalendarEvent[];
    selectedStartDate: Date;
    activeMonthDate: Date;
}): CalendarEvent[] => {
    const updatedEvents = bookingEvents.map((event) => moveBookingEventEndDateBackOneDay(event));
    const selectEvent: CalendarEvent = {
        id: 'select',
        type: EventTypes.SELECT,
        startDate: selectedStartDate,
        endDate: selectedStartDate,
    };
    const expiredEvents = getExpiredEventsWithSelectEvent({ selectedStartDate, activeMonthDate });
    const minRentalDurationEvents = createMinRentalDurationEventsPerDate({
        availabilityPerDate,
        bookingEvents: updatedEvents,
        selectEvent,
    });
    const maxRentalDurationEvents = createMaxRentalDurationEventsPerDate({
        availabilityPerDate,
        selectEvent,
    });
    const invalidCheckoutDayEventList = getInvalidCheckoutDayEventListPerDate({
        bookingEvents: updatedEvents,
        availabilityPerDate,
        activeMonthDate,
        selectedStartDate,
        minRentalDurationEvents,
        maxRentalDurationEvents,
    });

    return [
        selectEvent,
        ...updatedEvents,
        ...expiredEvents,
        ...invalidCheckoutDayEventList,
        ...minRentalDurationEvents,
        ...maxRentalDurationEvents,
    ];
};

const bookingEventsWithSelectStartAndEnd = ({
    bookingEvents,
    availabilityPerDate,
    activeMonthDate,
    today,
    selectedStartDate,
    selectedEndDate,
}: {
    bookingEvents: CalendarEvent[];
    availabilityPerDate?: AvailabilityPerDate[];
    activeMonthDate: Date;
    today: Date;
    selectedStartDate: Date;
    selectedEndDate: Date;
}): CalendarEvent[] => {
    const updatedEvents = bookingEvents.map((event) => moveBookingEventEndDateBackOneDay(event));
    const selectEvent: CalendarEvent = {
        id: 'select',
        type: EventTypes.SELECT,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
    };
    const eventFollowingSelectionEvent = getBookingEventFollowingSelectionEvent({
        bookingEvents: updatedEvents,
        selectEvent,
    });

    let invalidCheckinDayEventList = getInvalidCheckinDayEventListPerDate({
        bookingEvents,
        availabilityPerDate,
        activeMonthDate,
        today,
    });
    invalidCheckinDayEventList = invalidCheckinDayEventList.filter(
        (event) => !(event.startDate >= selectEvent.startDate && event.endDate <= selectEvent.endDate),
    );

    if (eventFollowingSelectionEvent && isOnSameDay(selectEvent.endDate, eventFollowingSelectionEvent.startDate)) {
        const dayAfterStartDate = moveBookingEventStartDateForwardOneDay(eventFollowingSelectionEvent);

        eventFollowingSelectionEvent.startDate = dayAfterStartDate.startDate;
        eventFollowingSelectionEvent.endDate = dayAfterStartDate.endDate;
    }

    return [selectEvent, ...invalidCheckinDayEventList, ...updatedEvents];
};

type UseDatesProps = {
    availabilityPerDate?: AvailabilityPerDate[];
    events: CalendarEvent[];
    activeMonthDate: Date;
    selectedStartDate: Date | null;
    selectedEndDate: Date | null;
    today: Date;
};
export const useCalendarEvents = ({
    availabilityPerDate,
    events,
    activeMonthDate,
    selectedStartDate,
    selectedEndDate,
    today,
}: UseDatesProps) => {
    const bookingEvents = [...events]
        // We shouldn't be dealing with other events being passed in here
        .filter(
            (event) =>
                event.type === EventTypes.BOOKING || event.type === EventTypes.BOOKING_SELECT || event.type === EventTypes.BOOKING_BLOCKED,
        )
        // Sort by startDate. We don't support overlapping booking events
        .sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
        // Clone the array's objects too to make sure we don't mutate the originals
        .map((event) => ({ ...event }));
    const updatedEvents: CalendarEvent[] =
        selectedStartDate && selectedEndDate
            ? bookingEventsWithSelectStartAndEnd({
                  bookingEvents,
                  availabilityPerDate,
                  activeMonthDate,
                  today,
                  selectedStartDate,
                  selectedEndDate,
              })
            : selectedStartDate
              ? bookingEventsWithSelectStartEvent({
                    // checkout
                    bookingEvents,
                    selectedStartDate,
                    availabilityPerDate,
                    activeMonthDate,
                })
              : bookingEventsWithoutSelectEvent({
                    //checkin
                    availabilityPerDate,
                    bookingEvents,
                    activeMonthDate,
                    today,
                });

    return { updatedEvents };
};
